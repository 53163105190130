import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";
import { ThemeProvider } from "@mui/material/styles";
import {
  CircularProgress,
  Typography,
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
// import PrintIcon from "@mui/icons-material/Print";
import theme from "../config/theme";
import NavigationBar from "../components/NavigationBar";
import { copyrightText } from "../config/config";
import { trackPageView, trackEvent } from "../config/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";

const PaymentConfirmation = () => {
  const [paymentStatus, setPaymentStatus] = useState("loading");
  const [paymentDetails, setPaymentDetails] = useState(null);
  const location = useLocation();
  const [myPaymentIntentId, setMyPaymentIntentId] = useState(null);
  const [user] = useAuthState(auth);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    trackPageView("Payment Confirmation");

    const verifyPayment = async () => {
      const queryParams = new URLSearchParams(location.search);
      const paymentIntentId = queryParams.get("payment_intent");

      setMyPaymentIntentId(paymentIntentId);

      if (paymentIntentId) {
        try {
          const verifyPaymentStatus = httpsCallable(
            functions,
            "verifyPaymentStatus"
          );
          const result = await verifyPaymentStatus({ paymentIntentId });

          setPaymentStatus(result.data.status);
          setPaymentDetails(result.data);

          if (result.data.status === "succeeded") {
            // console.log(
            //   `Updated ${result.data.updatedImagesCount} images to processing status`
            // );
            trackEvent("Images Status Updated", {
              count: result.data.updatedImagesCount,
            });
          }
        } catch (error) {
          console.error("Error verifying payment:", error);
          setPaymentStatus("error");
        }
      } else {
        setPaymentStatus("error");
      }
    };

    verifyPayment();
  }, [location]);

  // const handlePrint = () => {
  //   window.print();
  // };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount / 100);
  };

  const renderReceipt = () => {
    if (!paymentDetails) return null;

    const {
      amount,
      currency,
      itemCount,
      pricePerItem,
      language,
      subtotal,
      stripeFee,
    } = paymentDetails;

    return (
      <Paper elevation={3} sx={{ p: 1, mt: 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Box
            sx={{
              mr: 2,
              width: "50px",
              height: "50px",
              flexShrink: 0,
            }}
          >
            <img
              src="/logo512.png"
              alt="QuiltTale Logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Typography
            variant="h5"
            align="center"
            sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
          >
            QuiltTale.com Receipt
          </Typography>
        </Box>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Typography variant="body2">
            <strong>Payment Date:</strong> {new Date().toLocaleDateString()}
          </Typography>
          <Typography variant="body2">
            <strong>Customer Email:</strong> {user ? user.email : "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Payment Confirmation #:</strong>{" "}
            {myPaymentIntentId.slice(-5)}
          </Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Story Generation
                  <Typography variant="body2" color="textSecondary">
                    Language: {language}
                  </Typography>
                </TableCell>
                <TableCell align="center">{itemCount}</TableCell>
                <TableCell align="right">
                  {formatCurrency(pricePerItem * 100, currency)}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(subtotal, currency)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  Subtotal
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(subtotal, currency)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  Processing Fee
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(stripeFee, currency)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <strong>Total</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{formatCurrency(amount, currency)}</strong>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={3} align="right">
                  <strong>Amount Paid</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{formatCurrency(amount, currency)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  const renderContent = () => {
    switch (paymentStatus) {
      case "loading":
        return (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        );
      case "error":
        return (
          <Box sx={{ textAlign: "center", my: 4 }}>
            <Typography variant="h6" color="error" gutterBottom>
              There was an error verifying your payment.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please contact our support team for assistance.
            </Typography>
            <Button
              component={Link}
              to="/feedback"
              variant="outlined"
              color="secondary"
              sx={{ mt: 2 }}
            >
              Go to Feedback
            </Button>
          </Box>
        );
      case "succeeded":
        return (
          <Box sx={{ textAlign: "center", my: 4 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
            >
              Thank You for Your Purchase!
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Your payment has been successfully processed. We're excited to
              generate stories for your quilts!
            </Typography>
            {renderReceipt()}
            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                gap: 2,
              }}
            >
              {/* <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                onClick={handlePrint}
                fullWidth={isMobile}
              >
                Print Receipt
              </Button> */}
              <Button
                component={Link}
                to="/gallery"
                variant="outlined"
                color="secondary"
                fullWidth={isMobile}
              >
                Go to Gallery
              </Button>
            </Box>
          </Box>
        );
      default:
        return (
          <Box sx={{ textAlign: "center", my: 4 }}>
            <Typography variant="h6" gutterBottom>
              Your payment is being processed.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please wait for confirmation. This may take a few moments.
            </Typography>
            <CircularProgress sx={{ mt: 2 }} />
          </Box>
        );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <NavigationBar />
        <Box
          sx={{
            flexGrow: 1,
            py: 3,
            px: isMobile ? 0 : 2,
            background: theme.palette.background.default,
          }}
        >
          <Container maxWidth="md" disableGutters={isMobile}>
            {renderContent()}
          </Container>
        </Box>
        <Box
          sx={{
            py: 3,
            background: theme.palette.background.default,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {copyrightText}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PaymentConfirmation;
