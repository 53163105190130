import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Container,
  Box,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PaletteIcon from "@mui/icons-material/Palette";
import theme from "../config/theme";
import { copyrightText } from "../config/config";
// import { auth } from "../config/firebase";
// import { sendSignInLinkToEmail } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { trackPageView, trackEvent } from "../config/analytics";
import ResponsiveBanner from "../components/ResponsiveBanner";
import { functions } from "../config/firebase";

const LandingPage = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    trackPageView("Landing Page");
  }, []);

  const resubmitEmail = () => {
    setEmailSent(false);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const encodedEmail = encodeURIComponent(email);
  //   const actionCodeSettings = {
  //     url: `${window.location.origin}/finishSignUp?email=${encodedEmail}`,
  //     handleCodeInApp: true,
  //   };
  //   try {
  //     await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  //     setEmailSent(true);
  //     trackEvent("Send Sign In Link", { email: email });
  //   } catch (error) {
  //     console.error("Error sending email:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const sendCustomSignInEmail = httpsCallable(
        functions,
        "sendCustomSignInEmail"
      );
      const result = await sendCustomSignInEmail({ email });

      setEmailSent(true);
      trackEvent("Send Custom Email", {
        email: email,
        isExistingUser: result.data.isExistingUser,
      });

      // You can use result.data.isExistingUser to show different messages to new vs returning users
      // if (result.data.isExistingUser) {
      //   // Show a message for returning users
      //   console.log("Welcome back! Check your email to sign in.");
      // } else {
      //   // Show a message for new users
      //   console.log(
      //     "Welcome to QuiltTale! Check your email to complete your sign up."
      //   );
      // }
    } catch (error) {
      console.error("Error sending custom email:", error);
      // Handle the error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          background: theme.palette.background.default,
        }}
      >
        <Container maxWidth="md">
          <Card sx={{ boxShadow: 3, maxWidth: "800px", margin: "0 auto" }}>
            <CardContent sx={{ p: { xs: 2, sm: 4 } }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    width: isMobile ? "80px" : "100px",
                    height: isMobile ? "80px" : "100px",
                    flexShrink: 0,
                    mb: isMobile ? 2 : 0,
                    mr: isMobile ? 0 : 2,
                  }}
                >
                  <img
                    src="logo512.png"
                    alt="QuiltTale Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Typography
                    variant="h1"
                    component="div"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: { xs: "2.5rem", sm: "3rem", md: "3.5rem" },
                      lineHeight: 1,
                      mb: 0,
                    }}
                  >
                    <span style={{ color: theme.palette.primary.main }}>
                      QUILT
                    </span>
                    <span style={{ color: theme.palette.secondary.main }}>
                      TALE
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.5rem" },
                      fontWeight: "normal",
                      alignSelf: "center",
                    }}
                  >
                    Stories For Your Crafts
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                paragraph
                sx={{
                  fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
                  mb: 3,
                }}
              >
                Transform your quilts into captivating narratives with our
                unique storytelling tool, weaving the threads of imagination
                into every stitch.
              </Typography>

              {emailSent ? (
                <Box sx={{ textAlign: "center", my: 3 }}>
                  <Typography
                    variant="h5"
                    align="center"
                    color={theme.palette.primary.main}
                    sx={{
                      mb: 0,
                      fontSize: { xs: "1.2rem", sm: "1.3rem", md: "1.4rem" },
                    }}
                  >
                    Verification link sent! Check your email.
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    color={theme.palette.primary.main}
                    sx={{ mb: 2 }}
                  >
                    If you don't see it, please check your <strong>spam</strong>{" "}
                    folder.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={resubmitEmail}
                  >
                    Resend Link
                  </Button>
                </Box>
              ) : (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 1,
                    alignItems: "stretch",
                    mb: 3,
                  }}
                >
                  <TextField
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    variant="outlined"
                    required
                    sx={{ flexGrow: 1 }}
                    disabled={isLoading}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{
                      width: { xs: "100%", sm: "200px" },
                      height: "56px",
                      whiteSpace: "nowrap",
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Get Started"
                    )}
                  </Button>
                </Box>
              )}

              <Typography
                variant="h3"
                align="center"
                color="text.secondary"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
                  mb: 3,
                }}
              >
                Join thousands of quilters bringing their creations to life
                through stories!
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 3,
                  mb: 3,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <LockIcon color="primary" />
                  <Typography variant="body1">Secure & Trustworthy</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <PaletteIcon color="secondary" />
                  <Typography variant="body1">Unleash Creativity</Typography>
                </Box>
              </Box>

              {/* <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                <img
                  src="./assets/banner.jpeg"
                  alt="Garden of Memories"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "8px",
                    boxShadow: theme.shadows[4],
                  }}
                />
              </Box> */}
              <ResponsiveBanner />

              <Typography
                variant="h3"
                align="center"
                color="text.secondary"
                sx={{ fontSize: { xs: "1.1rem", sm: "1.2rem", md: "1.3rem" } }}
              >
                Our tool analyzes your quilt's colors, patterns, and textures,
                blending tradition and emotion to create a unique story that
                brings your quilt to life.
              </Typography>
            </CardContent>
          </Card>
        </Container>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 4, textAlign: "center" }}
        >
          {copyrightText}
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
