// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app, process.env.REACT_APP_DATABASE_NAME, {
  experimentalForceLongPolling: true,
});
const storage = getStorage(app);
const functions = getFunctions(app);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

// Set authentication persistence to session
setPersistence(auth, browserLocalPersistence);
// .then(() => {
//   console.log("Firebase Auth persistence set to browserLocalPersistence");
// })
// .catch((error) => {
//   console.error("Error setting persistence:", error);
// });

// Connect to Firebase Emulators if running locally
if (process.env.REACT_APP_USE_FIREBASE_EMULATOR === "true") {
  console.log("Using Firebase Emulators");
  connectFunctionsEmulator(functions, "localhost", 5001);
  // Uncomment these if you're using other emulators as well
  // connectAuthEmulator(auth, "http://localhost:9099");
  // connectFirestoreEmulator(firestore, 'localhost', 8080);
  // connectStorageEmulator(storage, "localhost", 9199);
}

export { app, analytics, auth, firestore, storage, functions };
