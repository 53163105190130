import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import { functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({
  clientSecret,
  itemCount,
  pricePerItem,
  language,
  onSuccess,
  onError,
  userEmail,
  amount,
  subtotal,
  stripeFee,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(userEmail || "");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      const paymentIntentId = clientSecret.split("_secret_")[0];

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/paymentConfirmation?payment_intent=${paymentIntentId}`,
          receipt_email: email,
        },
        redirect: "always",
      });

      if (error) {
        onError(error);
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  const CostBreakdownRow = ({
    label,
    amount,
    isTotal = false,
    details = null,
  }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: 1,
        alignItems: "baseline",
      }}
    >
      <Box>
        <Typography
          variant="body1"
          fontWeight={isTotal ? "bold" : "normal"}
          component="span"
        >
          {label}
        </Typography>
        {details && (
          <Typography
            variant="body2"
            color="text.secondary"
            component="span"
            sx={{ ml: 1 }}
          >
            ({details})
          </Typography>
        )}
      </Box>
      <Typography variant="body1" fontWeight={isTotal ? "bold" : "normal"}>
        ${(amount / 100).toFixed(2)}
      </Typography>
    </Box>
  );

  return (
    <form onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        options={{
          defaultValues: {
            email: email,
          },
        }}
        onChange={(event) => {
          setEmail(event.value.email);
        }}
      />
      <PaymentElement
        options={{
          layout: "tabs",
          paymentMethodOrder: ["link", "card"],
        }}
      />
      <Box
        sx={{ mt: 3, mb: 2, backgroundColor: "#f5f5f5", p: 2, borderRadius: 1 }}
      >
        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>
        <CostBreakdownRow
          label="Subtotal"
          amount={subtotal}
          details={`${itemCount} ${itemCount === 1 ? "image" : "images"}, ${
            language.name
          }`}
        />
        <CostBreakdownRow label="Processing Fee" amount={stripeFee} />
        <Divider sx={{ my: 1 }} />
        <CostBreakdownRow label="Total" amount={amount} isTotal={true} />
      </Box>
      <Button
        type="submit"
        disabled={!stripe || loading}
        variant="contained"
        color="primary"
        fullWidth
        size="large"
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          `Pay $${(amount / 100).toFixed(2)}`
        )}
      </Button>
    </form>
  );
};

const StripeCheckoutContent = ({
  clientSecret,
  itemCount,
  pricePerItem,
  language,
  onSuccess,
  onError,
  userEmail,
  amount,
  subtotal,
  stripeFee,
}) => {
  if (!clientSecret) {
    return (
      <Typography variant="body1" color="error" align="center">
        Unable to load payment form. Please try again later.
      </Typography>
    );
  }

  return (
    <Elements
      key={clientSecret}
      options={{ clientSecret, loader: "auto" }}
      stripe={stripePromise}
    >
      <CheckoutForm
        clientSecret={clientSecret}
        itemCount={itemCount}
        pricePerItem={pricePerItem}
        language={language}
        onSuccess={onSuccess}
        onError={onError}
        userEmail={userEmail}
        amount={amount}
        subtotal={subtotal}
        stripeFee={stripeFee}
      />
    </Elements>
  );
};

const StripeCheckout = ({
  itemCount,
  pricePerItem,
  language,
  onSuccess,
  onError,
  userEmail,
  imageIds,
}) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [stripeFee, setStripeFee] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");

    createPaymentIntent({
      itemCount: itemCount,
      pricePerItem: pricePerItem,
      language: language.name,
      imageIds: imageIds,
    })
      .then((result) => {
        if (result.data && result.data.clientSecret) {
          setClientSecret(result.data.clientSecret);
          setAmount(result.data.amount);
          setSubtotal(result.data.subtotal);
          setStripeFee(result.data.stripeFee);
        } else {
          throw new Error("Invalid response from server");
        }
      })
      .catch((error) => {
        console.error("Error creating PaymentIntent:", error);
        onError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [itemCount, pricePerItem, language, onError, user, imageIds]);

  return (
    <Box sx={{ width: "100%", maxWidth: 500, mx: "auto" }}>
      <Typography variant="h5" gutterBottom align="center">
        Complete Your Purchase
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <StripeCheckoutContent
          clientSecret={clientSecret}
          itemCount={itemCount}
          pricePerItem={pricePerItem}
          language={language}
          onSuccess={onSuccess}
          onError={onError}
          userEmail={userEmail}
          amount={amount}
          subtotal={subtotal}
          stripeFee={stripeFee}
        />
      )}
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        Payment processed securely by Stripe.{" "}
        <a
          href="https://stripe.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </Typography>
    </Box>
  );
};

export default StripeCheckout;
