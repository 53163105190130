import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { auth, firestore } from "../config/firebase";
import { ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Button,
  useMediaQuery,
} from "@mui/material";
import NavigationBar from "../components/NavigationBar";
import theme from "../config/theme";
import { copyrightText } from "../config/config";
import { trackPageView } from "../config/analytics";
import { Link } from "react-router-dom";
import ImageCard from "../components/ImageCard";
import { useQuery } from "@tanstack/react-query";

const MyGallery = () => {
  const [user] = useAuthState(auth);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    trackPageView("My Gallery");
  }, []);

  const {
    data: userImages,
    isLoading: isLoadingUserImages,
    error: userImagesError,
  } = useQuery({
    queryKey: ["userImages", user?.uid],
    queryFn: async () => {
      if (!user) return [];
      const q = query(
        collection(firestore, `users/${user.uid}/uploads`),
        where("status", "in", ["processing", "ready"]),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!user,
  });

  const {
    data: exampleImages,
    isLoading: isLoadingExamples,
    error: exampleImagesError,
  } = useQuery({
    queryKey: ["exampleImages"],
    queryFn: async () => {
      const q = query(collection(firestore, "examples"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!user && (!userImages || userImages.length === 0),
  });

  const isLoading = isLoadingUserImages || isLoadingExamples;
  const error = userImagesError || exampleImagesError;

  const images =
    userImages && userImages.length > 0 ? userImages : exampleImages;

  const { processingCount, readyCount } = React.useMemo(() => {
    if (!images) return { processingCount: 0, readyCount: 0 };
    const processing = images.filter((image) => image.status === "processing");
    const ready = images.filter(
      (image) => image.status === "ready" || !image.status
    ); // examples don't have status
    return {
      processingCount: processing.length,
      readyCount: ready.length,
    };
  }, [images]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Alert severity="error">Error loading images: {error.message}</Alert>
    );
  }

  if (!user) {
    return (
      <Alert severity="warning">Please log in to view your gallery.</Alert>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <NavigationBar />
        <Box
          sx={{
            flexGrow: 1,
            py: 3,
            px: 2,
            background: theme.palette.background.default,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h1"
              align="center"
              gutterBottom
              sx={{ fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" } }}
            >
              My Gallery
            </Typography>
            <Card sx={{ mb: 4, boxShadow: 3 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 2,
                    mb: -1,
                  }}
                >
                  {processingCount > 0 ? (
                    <>
                      <Typography variant="body1">
                        Stories in progress: {processingCount}
                      </Typography>
                      <Typography variant="body1">
                        Estimated time is usually 1 minute per image. You will
                        be notified via email when the stories are ready.
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 2,
                      }}
                    >
                      {readyCount > 0 ? (
                        <Typography variant="body1">
                          {userImages && userImages.length > 0
                            ? `Click on images to view stories. Number of stories: ${readyCount}.`
                            : "Here are some example stories. Upload your own images to create personalized stories!"}
                        </Typography>
                      ) : (
                        <Typography variant="body1">
                          Your gallery is empty. Upload images to get started!
                        </Typography>
                      )}
                      {readyCount === 0 && (
                        <Button
                          to="/upload"
                          variant="outlined"
                          color="secondary"
                          component={Link}
                        >
                          Go to Upload
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
            {images && images.length > 0 ? (
              <Grid container spacing={2}>
                {images.map((image) => (
                  <Grid item xs={12} sm={6} md={4} key={image.id}>
                    <ImageCard
                      image={image}
                      status={image.status || "ready"}
                      isMobile={isMobile}
                      isExample={!image.status}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <></>
            )}
          </Container>
        </Box>
        <Box
          sx={{
            py: 3,
            background: theme.palette.background.default,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {copyrightText}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MyGallery;

// import React, { useEffect, useState, useMemo } from "react";
// import { useAuthState } from "react-firebase-hooks/auth";
// import {
//   collection,
//   query,
//   where,
//   orderBy,
//   onSnapshot,
// } from "firebase/firestore";
// import { auth, firestore } from "../config/firebase";
// import { ThemeProvider } from "@mui/material/styles";
// import {
//   Typography,
//   Container,
//   Box,
//   Grid,
//   Card,
//   CardContent,
//   CircularProgress,
//   Alert,
//   Button,
//   useMediaQuery,
// } from "@mui/material";
// import NavigationBar from "../components/NavigationBar";
// import theme from "../config/theme";
// import { copyrightText } from "../config/config";
// import { trackPageView } from "../config/analytics";
// import { Link } from "react-router-dom";
// import ImageCard from "../components/ImageCard";

// const MyGallery = () => {
//   const [user, userLoading, userError] = useAuthState(auth);
//   const [galleryState, setGalleryState] = useState({
//     imageData: {},
//     processingStatus: {},
//     isInitialLoading: true,
//     error: null,
//   });
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   useEffect(() => {
//     trackPageView("My Gallery");
//   }, []);

//   useEffect(() => {
//     if (!user) return;

//     const q = query(
//       collection(firestore, `users/${user.uid}/uploads`),
//       where("status", "in", ["processing", "ready"]),
//       orderBy("timestamp", "desc")
//     );

//     const unsubscribe = onSnapshot(
//       q,
//       (querySnapshot) => {
//         setGalleryState((prevState) => {
//           const newImageData = { ...prevState.imageData };
//           const newProcessingStatus = { ...prevState.processingStatus };
//           let hasChanges = false;

//           querySnapshot.docChanges().forEach((change) => {
//             const doc = change.doc;
//             const data = doc.data();
//             if (change.type === "added" || change.type === "modified") {
//               newImageData[doc.id] = {
//                 id: doc.id,
//                 downloadURL: data.downloadURL,
//                 title: data.title,
//                 story: data.story,
//               };
//               newProcessingStatus[doc.id] = data.status;
//               hasChanges = true;
//             } else if (change.type === "removed") {
//               delete newImageData[doc.id];
//               delete newProcessingStatus[doc.id];
//               hasChanges = true;
//             }
//           });

//           if (hasChanges || prevState.isInitialLoading) {
//             return {
//               imageData: newImageData,
//               processingStatus: newProcessingStatus,
//               isInitialLoading: false,
//               error: null,
//             };
//           }
//           return prevState;
//         });
//       },
//       (err) => {
//         console.error("Error fetching images:", err);
//         setGalleryState((prevState) => ({
//           ...prevState,
//           error: err,
//           isLoading: false,
//         }));
//       }
//     );

//     // Cleanup function to unsubscribe from the listener when the component unmounts
//     return () => unsubscribe();
//   }, [user]);

//   const { processingCount, readyCount, memoizedImageIds } = useMemo(() => {
//     const statusArray = Object.values(galleryState.processingStatus);
//     const processing = statusArray.filter((status) => status === "processing");
//     const ready = statusArray.filter((status) => status === "ready");
//     return {
//       processingCount: processing.length,
//       readyCount: ready.length,
//       memoizedImageIds: Object.keys(galleryState.imageData),
//     };
//   }, [galleryState.processingStatus, galleryState.imageData]);

//   if (userLoading || galleryState.isInitialLoading) {
//     return <CircularProgress />;
//   }

//   if (userError) {
//     return (
//       <Alert severity="error">Error loading user: {userError.message}</Alert>
//     );
//   }

//   if (!user) {
//     return (
//       <Alert severity="warning">Please log in to view your gallery.</Alert>
//     );
//   }

//   return (
//     <ThemeProvider theme={theme}>
//       <Box
//         sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
//       >
//         <NavigationBar />
//         <Box
//           sx={{
//             flexGrow: 1,
//             py: 3,
//             px: 2,
//             background: theme.palette.background.default,
//           }}
//         >
//           <Container maxWidth="lg">
//             <Typography
//               variant="h1"
//               align="center"
//               gutterBottom
//               sx={{ fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" } }}
//             >
//               My Gallery
//             </Typography>
//             <Card sx={{ mb: 4, boxShadow: 3 }}>
//               <CardContent>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: isMobile ? "column" : "row",
//                     justifyContent: "space-evenly",
//                     alignItems: "center",
//                     flexWrap: "wrap",
//                     gap: 2,
//                     mb: -1,
//                   }}
//                 >
//                   {processingCount > 0 ? (
//                     <>
//                       <Typography variant="body1">
//                         Stories in progress: {processingCount}
//                       </Typography>
//                       <Typography variant="body1">
//                         Estimated time is usually 1 minute per image. You will
//                         be notified via email when the stories are ready.
//                       </Typography>
//                     </>
//                   ) : (
//                     <Box
//                       sx={{
//                         textAlign: "center",
//                         display: "flex",
//                         flexDirection: isMobile ? "column" : "row",
//                         alignItems: "center",
//                         flexWrap: "wrap",
//                         gap: 2,
//                       }}
//                     >
//                       {readyCount > 0 ? (
//                         <Typography variant="body1">
//                           Click on images to view stories. Number of stories:{" "}
//                           {readyCount}.
//                         </Typography>
//                       ) : (
//                         <Typography variant="body1">
//                           Your gallery is empty. Upload images to get started!
//                         </Typography>
//                       )}
//                       {readyCount === 0 && (
//                         <Button
//                           to="/upload"
//                           variant="outlined"
//                           color="secondary"
//                           component={Link}
//                         >
//                           Go to Upload
//                         </Button>
//                       )}
//                     </Box>
//                   )}
//                 </Box>
//               </CardContent>
//             </Card>
//             {galleryState.isInitialLoading ? (
//               <Box sx={{ display: "flex", justifyContent: "center" }}>
//                 <CircularProgress />
//               </Box>
//             ) : galleryState.error ? (
//               <Alert severity="error">
//                 Error loading images: {galleryState.error.message}
//               </Alert>
//             ) : memoizedImageIds.length > 0 ? (
//               <Grid container spacing={2}>
//                 {memoizedImageIds.map((imageId) => (
//                   <Grid item xs={12} sm={6} md={4} key={imageId}>
//                     <ImageCard
//                       image={galleryState.imageData[imageId]}
//                       status={galleryState.processingStatus[imageId]}
//                       isMobile={isMobile}
//                     />
//                   </Grid>
//                 ))}
//               </Grid>
//             ) : (
//               <></>
//             )}
//           </Container>
//         </Box>
//         <Box
//           sx={{
//             py: 3,
//             background: theme.palette.background.default,
//             textAlign: "center",
//           }}
//         >
//           <Typography variant="body2" color="text.secondary">
//             {copyrightText}
//           </Typography>
//         </Box>
//       </Box>
//     </ThemeProvider>
//   );
// };

// export default MyGallery;
