import React, { useEffect } from "react";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, CircularProgress, Box } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";

const FinishSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    const completeSignIn = async () => {
      if (loading) return;

      // Get the email from the URL if it exists
      let email = new URL(window.location.href).searchParams.get("email");
      email = email ? decodeURIComponent(email) : null;

      // Get the redirect parameter from the URL
      const routingParam = new URLSearchParams(location.search).get("redirect");

      // If the same user is already signed in, redirect to the appropriate page
      if (user && user.email === email) {
        if (routingParam) {
          // console.log(
          //   `User is already signed in, redirecting to ${routingParam}`
          // );
          navigate(routingParam);
        } else {
          // console.log("User is already signed in, redirecting to /upload");
          navigate("/upload");
        }
        return;
      } else if (user) {
        console.error("User is already signed in with a different email");
        // Sign out the user and continue with the sign-in process
        await signOut(auth);
      }

      // If the user is not signed in, complete the sign-in process
      if (isSignInWithEmailLink(auth, window.location.href)) {
        if (!email) {
          console.error("No email found in URL");
          navigate("/");
          return;
        }

        try {
          await signInWithEmailLink(auth, email, window.location.href);
          // console.log("Successfully signed in with email link");
          // Redirect to the appropriate page
          navigate(routingParam || "/upload");
        } catch (error) {
          console.error("Error signing in with email link:", error);
          navigate("/");
        }
      } else {
        console.error("Invalid sign-in link");
        navigate("/");
      }
    };

    completeSignIn();
  }, [navigate, user, loading, location]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={40} sx={{ mb: 2 }} />
        <Typography variant="h5" color="primary" align="center">
          Checking authentication status...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress size={40} sx={{ mb: 2 }} />
      <Typography variant="h5" color="primary" align="center">
        Completing sign in...
      </Typography>
    </Box>
  );
};

export default FinishSignUp;

// import React, { useEffect } from "react";
// import { auth } from "../config/firebase";
// import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
// import { Typography, CircularProgress, Box } from "@mui/material";
// import { useAuthState } from "react-firebase-hooks/auth";

// const FinishSignUp = () => {
//   const navigate = useNavigate();
//   const [user, loading] = useAuthState(auth);

//   useEffect(() => {
//     const completeSignIn = async () => {
//       if (loading) return;

//       if (user) {
//         console.log("User is already signed in, redirecting to /upload");
//         navigate("/upload");
//         return;
//       }

//       if (isSignInWithEmailLink(auth, window.location.href)) {
//         let email = new URL(window.location.href).searchParams.get("email");
//         email = email ? decodeURIComponent(email) : null;

//         if (!email) {
//           console.error("No email found in URL");
//           navigate("/");
//           return;
//         }

//         try {
//           await signInWithEmailLink(auth, email, window.location.href);
//           console.log("Successfully signed in with email link");
//           navigate("/upload");
//         } catch (error) {
//           console.error("Error signing in with email link:", error);
//           navigate("/");
//         }
//       } else {
//         console.error("Invalid sign-in link");
//         navigate("/");
//       }
//     };

//     completeSignIn();
//   }, [navigate, user, loading]);

//   if (loading) {
//     return (
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//         <CircularProgress size={40} sx={{ mb: 2 }} />
//         <Typography variant="h5" color="primary" align="center">
//           Checking authentication status...
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100vh",
//       }}
//     >
//       <CircularProgress size={40} sx={{ mb: 2 }} />
//       <Typography variant="h5" color="primary" align="center">
//         Completing sign in...
//       </Typography>
//     </Box>
//   );
// };

// export default FinishSignUp;
