import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { collection, addDoc } from "firebase/firestore";
import { auth, firestore } from "../config/firebase";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import NavigationBar from "../components/NavigationBar";
import theme from "../config/theme";
import { trackPageView, trackEvent } from "../config/analytics";
import { ThemeProvider } from "@mui/material/styles";
import { copyrightText } from "../config/config";

const Feedback = () => {
  const [user] = useAuthState(auth);
  const [feedback, setFeedback] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const queryClient = useQueryClient();

  const addFeedbackMutation = useMutation({
    mutationFn: async (feedbackData) => {
      const feedbackRef = collection(firestore, "feedback");
      await addDoc(feedbackRef, feedbackData);
    },
    onSuccess: () => {
      setIsSubmitted(true);
      setFeedback("");
      trackEvent("Submit Feedback", { success: true });
      queryClient.invalidateQueries({ queryKey: ["feedback"] });
      setSnackbarOpen(true);
    },
    onError: (error) => {
      console.error("Error submitting feedback:", error);
      trackEvent("Submit Feedback", { success: false, error: error.message });
      setSnackbarOpen(true);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!user || !feedback.trim()) return;

    const feedbackData = {
      userId: user.uid,
      userEmail: user.email,
      feedback: feedback.trim(),
      createdAt: new Date().toISOString(),
    };

    addFeedbackMutation.mutate(feedbackData);
  };

  const handleResetForm = () => {
    setIsSubmitted(false);
    setSnackbarOpen(false);
    setFeedback("");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    trackPageView("Feedback Page");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <NavigationBar />
        <Container
          maxWidth="md"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            py: { xs: 4, sm: 6 },
            px: { xs: 2, sm: 3 },
          }}
        >
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 } }}>
            <Typography
              variant="h1"
              align="center"
              gutterBottom
              sx={{ fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" } }}
            >
              We Value Your Feedback
            </Typography>
            <Typography
              variant="body1"
              align="center"
              paragraph
              sx={{ mb: { xs: 2, sm: 3 } }}
            >
              Your thoughts help us improve QuiltTale. Please share your
              experience or suggestions below.
            </Typography>
            {isSubmitted ? (
              <Box sx={{ textAlign: "center", mt: 3 }}>
                <Typography variant="h5" color="success.main" gutterBottom>
                  Thank you for your feedback! We appreciate your input.
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleResetForm}
                  sx={{ mt: 2 }}
                >
                  Send Another Feedback
                </Button>
              </Box>
            ) : (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 3 }}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  variant="outlined"
                  label="Your Feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  required
                  sx={{ mb: 3 }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={!feedback.trim() || addFeedbackMutation.isPending}
                  startIcon={
                    addFeedbackMutation.isPending ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {addFeedbackMutation.isPending
                    ? "Submitting..."
                    : "Submit Feedback"}
                </Button>
              </Box>
            )}
          </Paper>
        </Container>
        <Box
          sx={{
            py: 3,
            background: theme.palette.background.default,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {copyrightText}
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={isSubmitted ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isSubmitted
            ? "Feedback submitted successfully!"
            : "Error submitting feedback. Please try again."}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Feedback;
