import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import theme from "../config/theme";
import { languages } from "../config/languages";
import {
  copyrightText,
  maxImageDimension,
  maxImageUploads,
} from "../config/config";
import NavigationBar from "../components/NavigationBar";
import { trackPageView, trackEvent } from "../config/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore, storage } from "../config/firebase";
import {
  collection,
  writeBatch,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import StripeCheckout from "../components/StripeCheckout";
import { pricePerImage as defaultPricePerImage } from "../config/config";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

const BATCH_SIZE = 5; // Number of files to upload in parallel

const ProcessImages = () => {
  const [user] = useAuthState(auth);
  const [images, setImages] = useState([]);
  const [language, setLanguage] = useState("");
  const [detectedLanguage, setDetectedLanguage] = useState(null);
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);

  const { data: userPricePerImage, isLoading: isPriceLoading } = useQuery({
    queryKey: ["pricePerImage", user?.uid],
    queryFn: async () => {
      if (!user) return defaultPricePerImage;
      try {
        const userPriceDoc = await getDoc(doc(firestore, `users/${user.uid}`));
        if (userPriceDoc.exists()) {
          const data = userPriceDoc.data();
          return data.pricePerImage !== undefined
            ? data.pricePerImage
            : defaultPricePerImage;
        } else {
          // console.log("User document does not exist. Using default price.");
          return defaultPricePerImage;
        }
      } catch (error) {
        console.error("Error fetching price per image:", error);
        return defaultPricePerImage;
      }
    },
    enabled: !!user,
  });

  const pricePerImage = userPricePerImage ?? defaultPricePerImage;
  const totalCost = images.length * pricePerImage;
  const formattedCost = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalCost);

  useEffect(() => {
    const detectLanguage = () => {
      const browserLang = navigator.language || navigator.userLanguage;
      const langCode = browserLang.split("-")[0];
      const detectedLang =
        languages.find((lang) => lang.code === langCode) ||
        languages.find((lang) => lang.code === "en") ||
        languages[0];

      setDetectedLanguage(detectedLang);
      setLanguage(detectedLang);
    };

    detectLanguage();
    trackPageView("Process Images");
  }, []);

  const { data: uploadedImages, isLoading: isLoadingImages } = useQuery({
    queryKey: ["uploadedImages", user?.uid],
    queryFn: async () => {
      if (!user) return [];
      const q = query(
        collection(firestore, `users/${user.uid}/uploads`),
        where("status", "==", "uploaded")
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!user,
  });

  useEffect(() => {
    if (uploadedImages) {
      setImages(uploadedImages);
    }
  }, [uploadedImages]);

  const handleLanguageChange = (event, newValue) => {
    setLanguage(newValue || detectedLanguage);
  };

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > maxImageDimension) {
            height *= maxImageDimension / width;
            width = maxImageDimension;
          }
        } else {
          if (height > maxImageDimension) {
            width *= maxImageDimension / height;
            height = maxImageDimension;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(resolve, file.type);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const uploadMutation = useMutation({
    mutationFn: async (files) => {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");

      const results = [];
      for (let i = 0; i < files.length; i += BATCH_SIZE) {
        const batch = writeBatch(firestore);
        const uploadPromises = [];
        const endIndex = Math.min(i + BATCH_SIZE, files.length);

        for (let j = i; j < endIndex; j++) {
          const file = files[j];
          const imageId = Date.now().toString() + j; // Ensure unique ID
          const storageRef = ref(storage, `images/${user.uid}/${imageId}`);

          const uploadPromise = (async () => {
            try {
              const resizedBlob = await resizeImage(file);
              await uploadBytes(storageRef, resizedBlob);
              const downloadURL = await getDownloadURL(storageRef);

              const docRef = doc(
                collection(firestore, `users/${user.uid}/uploads`)
              );
              batch.set(docRef, {
                imageId,
                status: "uploaded",
                timestamp: new Date().toISOString(),
                downloadURL,
                originalFileName: file.name,
                uploadDevice: navigator.userAgent,
              });

              return { id: docRef.id, imageId, downloadURL };
            } catch (error) {
              console.error(`Error uploading file ${j}:`, error);
              throw error;
            }
          })();

          uploadPromises.push(uploadPromise);
        }

        // Wait for all uploads in the batch to complete
        const batchResults = await Promise.allSettled(uploadPromises);

        // Commit the batch write to Firestore
        await batch.commit();

        results.push(...batchResults);

        // You can report progress here if needed
        // console.log(`Uploaded batch ${i / BATCH_SIZE + 1}`);
      }

      return results;
    },
    onSuccess: (data, variables, context) => {
      // console.log("Batch upload mutation succeeded:", data);
      // Process results
      const successfulUploads = data
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value);
      const failedUploads = data.filter(
        (result) => result.status === "rejected"
      );

      // console.log(`Successfully uploaded ${successfulUploads.length} files`);
      // console.log(`Failed to upload ${failedUploads.length} files`);

      // Invalidate and refetch the uploads query
      queryClient.invalidateQueries(["uploadedImages", auth.currentUser?.uid]);

      // You could also update some local state here to reflect the new uploads
      // setImages(prev => [...prev, ...successfulUploads]);
    },
    onError: (error) => {
      console.error("Error in batch upload:", error);
      // Handle batch upload error, e.g., show a notification to the user
    },
  });

  // Usage in handleFileChange
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    if (images.length + files.length > maxImageUploads) {
      setIsLimitModalOpen(true);
      return;
    }

    try {
      await uploadMutation.mutateAsync(files);
    } catch (error) {
      console.error("Error in handleFileChange:", error);
      // Handle error, e.g., show a notification to the user
    }
  };

  // const uploadMutation = useMutation({
  //   mutationFn: async (file) => {
  //     const resizedBlob = await resizeImage(file);
  //     const imageId = Date.now().toString();
  //     const storageRef = ref(storage, `images/${user.uid}/${imageId}`);
  //     await uploadBytes(storageRef, resizedBlob);
  //     const downloadURL = await getDownloadURL(storageRef);
  //     const docRef = await addDoc(
  //       collection(firestore, `users/${user.uid}/uploads`),
  //       {
  //         imageId,
  //         status: "uploaded",
  //         timestamp: new Date().toISOString(),
  //         downloadURL,
  //         // language is added during payment
  //       }
  //     );
  //     return { id: docRef.id, imageId, downloadURL };
  //   },
  //   onSuccess: (data) => {
  //     queryClient.invalidateQueries(["uploadedImages", user?.uid]);
  //   },
  //   onError: (error) => {
  //     console.error("Error uploading image:", error);
  //   },
  // });

  // const handleFileChange = async (e) => {
  //   const files = Array.from(e.target.files);
  //   if (images.length + files.length > maxImageUploads) {
  //     setIsLimitModalOpen(true);
  //     return;
  //   }
  //   for (const file of files) {
  //     uploadMutation.mutate(file);
  //   }
  // };

  const handleLimitModalClose = () => {
    setIsLimitModalOpen(false);
  };

  const deleteMutation = useMutation({
    mutationFn: async (image) => {
      await deleteDoc(doc(firestore, `users/${user.uid}/uploads/${image.id}`));
      const storageRef = ref(storage, `images/${user.uid}/${image.imageId}`);
      await deleteObject(storageRef);
      return image.id;
    },
    onSuccess: (deletedId) => {
      queryClient.setQueryData(["uploadedImages", user?.uid], (oldData) => {
        return oldData.filter((img) => img.id !== deletedId);
      });
    },
    onError: (error) => {
      console.error("Error deleting image:", error);
    },
  });

  const removeImage = (image) => {
    deleteMutation.mutate(image);
  };

  const handleGenerateStories = async () => {
    setIsProcessing(true);
    if (parseFloat(pricePerImage) <= 0.0) {
      try {
        const functions = getFunctions();
        const verifyNoPayment = httpsCallable(functions, "verifyNoPayment");
        const result = await verifyNoPayment({
          language: language.name,
        });

        if (result.data.success) {
          // If the function call was successful, redirect to My Gallery
          trackEvent("Free Images Processing", {
            language: language.code,
            image_count: images.length,
          });
          // Add a slight delay before redirecting to ensure the user sees the loading state
          setTimeout(() => {
            setIsProcessing(false);
            navigate("/gallery");
          }, 500);
        } else {
          console.error("Failed to update image status:", result.data);
          setIsProcessing(false);
          // Handle the error (e.g., show an error message to the user)
        }
      } catch (error) {
        console.error("Error calling verifyNoPayment:", error);
        setIsProcessing(false);
        // Handle the error (e.g., show an error message to the user)
      }
    } else {
      setIsProcessing(false);
      setCheckoutOpen(true);
      trackEvent("Initiate Checkout", {
        language: language.code,
        image_count: images.length,
      });
    }
  };

  const handlePaymentSuccess = async () => {
    setCheckoutOpen(false);
    // Handle successful payment (e.g., generate stories, update UI)
    trackEvent("Payment Success", {
      language: language.code,
      image_count: images.length,
    });
  };

  const handlePaymentError = (error) => {
    console.error("Payment failed:", error);
    trackEvent("Payment Error", {
      error: error.message,
    });
    // Handle payment error (e.g., show error message to user)
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <NavigationBar />
        <Box
          sx={{
            flexGrow: 1,
            py: 3,
            px: 2,
            background: theme.palette.background.default,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h1"
              align="center"
              gutterBottom
              sx={{ fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" } }}
            >
              Generate Stories
            </Typography>
            <Card sx={{ mb: 3, boxShadow: 3 }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "stretch", md: "center" },
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "40%" },
                    minWidth: { md: "250px" },
                  }}
                >
                  <Autocomplete
                    value={language}
                    onChange={handleLanguageChange}
                    options={languages}
                    getOptionLabel={(option) => option?.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language for Stories"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.code}>
                        <Typography variant="body2">{option.name}</Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ ml: 1 }}
                        >
                          ({option.code})
                        </Typography>
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option?.code === value?.code
                    }
                    ListboxProps={{
                      style: { maxHeight: "250px" },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.primary.dark,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { xs: "center", sm: "flex-start" },
                      minWidth: { sm: "150px" },
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Images to process
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {images.length}
                    </Typography>
                  </Box>
                  {images.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: { xs: "center", sm: "flex-start" },
                        minWidth: { sm: "100px" },
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Total Cost
                      </Typography>
                      <Typography
                        variant="h6"
                        color="primary"
                        fontWeight="bold"
                      >
                        {isPriceLoading ? (
                          <CircularProgress size={20} />
                        ) : parseFloat(pricePerImage) <= 0.0 ? (
                          "Free"
                        ) : (
                          formattedCost
                        )}
                      </Typography>
                    </Box>
                  )}
                  {images.length > 0 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={() => handleGenerateStories()}
                      disabled={isProcessing || isPriceLoading}
                      sx={{ minWidth: "200px" }}
                    >
                      {isPriceLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Generate Stories"
                      )}
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ mb: 3, boxShadow: 3 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 3,
                    border: `2px dashed ${theme.palette.primary.main}`,
                    borderRadius: 2,
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                      "& .MuiSvgIcon-root, & .MuiTypography-root": {
                        color: "white",
                      },
                    },
                  }}
                  onClick={() => fileInputRef.current.click()}
                >
                  <CloudUploadIcon
                    sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
                  />
                  <Typography variant="body1" align="center">
                    Click to <strong>upload</strong> or{" "}
                    <strong>drag and drop</strong> images of your crafts for
                    story generation
                  </Typography>
                  <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Box>
              </CardContent>
            </Card>
            {isLoadingImages ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2}>
                {images.map((image) => (
                  <Grid item xs={6} sm={4} md={3} key={image.id}>
                    <Card
                      sx={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "100%",
                        overflow: "hidden",
                        boxShadow: 2,
                        backgroundColor: "grey.100",
                      }}
                    >
                      <CardContent
                        sx={{
                          p: 0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {image.downloadURL ? (
                          <img
                            src={image.downloadURL}
                            alt="Uploaded"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <Typography variant="body2" color="text.secondary">
                            Image not available
                          </Typography>
                        )}
                        <Button
                          onClick={() => removeImage(image)}
                          sx={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            minWidth: "unset",
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                            p: 0,
                            fontSize: "0.75rem",
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          X
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        </Box>
        <Box
          sx={{
            py: 3,
            background: theme.palette.background.default,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {copyrightText}
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={isLimitModalOpen}
        onClose={handleLimitModalClose}
        aria-labelledby="image-limit-dialog-title"
        aria-describedby="image-limit-dialog-description"
      >
        <DialogTitle id="image-limit-dialog-title">
          Image Upload Limit Reached
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="image-limit-dialog-description">
            You can only process a maximum of {maxImageUploads} images at a
            time. Please generate stories for the current images before
            uploading more.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLimitModalClose} color="primary">
            Understood
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={checkoutOpen}
        onClose={() => setCheckoutOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <StripeCheckout
            itemCount={images.length}
            pricePerItem={pricePerImage}
            totalCost={totalCost}
            language={language}
            onSuccess={handlePaymentSuccess}
            onError={handlePaymentError}
            userEmail={user?.email}
            // userEmail="customer@example.com"
            imageIds={images.map((img) => img.id)}
          />
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProcessing}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Processing your images...
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Please wait, you'll be redirected to your gallery soon.
          </Typography>
        </Box>
      </Backdrop>
    </ThemeProvider>
  );
};

export default ProcessImages;
