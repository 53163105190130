import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ProcessImages from "./pages/ProcessImages";
import PrivateRoute from "./components/PrivateRoute";
import FinishSignUp from "./pages/FinishSignUp";
import Feedback from "./pages/Feedback";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import MyGallery from "./pages/MyGallery";
import ReceiptsPage from "./pages/ReceiptsPage";
import { QueryClientProvider } from "./components/QueryClientProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  return (
    <QueryClientProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/finishSignUp" element={<FinishSignUp />} />
            <Route
              path="/upload"
              element={
                <PrivateRoute>
                  <ProcessImages />
                </PrivateRoute>
              }
            />
            <Route
              path="/gallery"
              element={
                <PrivateRoute>
                  <MyGallery />
                </PrivateRoute>
              }
            />
            <Route
              path="/feedback"
              element={
                <PrivateRoute>
                  <Feedback />
                </PrivateRoute>
              }
            />
            <Route
              path="/paymentConfirmation"
              element={
                <PrivateRoute>
                  <PaymentConfirmation />
                </PrivateRoute>
              }
            />
            <Route
              path="/receipts"
              element={
                <PrivateRoute>
                  <ReceiptsPage />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </Elements>
    </QueryClientProvider>
  );
};

export default App;
