import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1a1a6e", // Navy Blue
      light: "#3939a3",
      dark: "#0d0d35",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ff6f00", // Vibrant Orange
      light: "#ffa040",
      dark: "#c43e00",
      contrastText: "#000000",
    },
    background: {
      default: "#ffffff", // White background
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "#1a1a6e", // Navy Blue
      marginBottom: "1rem",
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
      color: "#1a1a6e", // Navy Blue
      marginBottom: "0.75rem",
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 500,
      color: "#1a1a6e", // Navy Blue
      marginBottom: "0.5rem",
      lineHeight: 1.4,
    },
    body1: {
      fontSize: "1rem",
      color: "#333333",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px",
          padding: "12px 24px", // Increased padding for wider buttons
        },
        containedPrimary: {
          backgroundColor: "#1a1a6e",
          "&:hover": {
            backgroundColor: "#3939a3",
          },
        },
        containedSecondary: {
          backgroundColor: "#ff6f00",
          color: "#000000",
          "&:hover": {
            backgroundColor: "#ffa040",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#1a1a6e",
            },
            "&:hover fieldset": {
              borderColor: "#3939a3",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#1a1a6e",
            },
          },
        },
      },
    },
  },
});

export default theme;
