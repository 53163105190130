import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  Snackbar,
  Chip,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PrintIcon from "@mui/icons-material/Print";
import { keyframes, styled } from "@mui/material/styles";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingBox = styled(Box)`
  animation: ${rotate} 2s linear infinite;
`;

const ImageCard = React.memo(({ image, status, isMobile, isExample }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCardClick = () => {
    if (status === "ready") {
      setIsExpanded(true);
    }
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const handleCopyContent = () => {
    const content = `${image.title}\n\n${image.story}`;
    navigator.clipboard.writeText(content).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handlePrintContent = () => {
    // Convert newlines to <br> tags for HTML printing
    const printableStory = image.story.replace(/\\n|\n/g, "<br>");
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>${image.title}</title>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lora&display=swap');
            body {
              font-family: 'Lora', serif;
              background-color: white;
              color: #333;
              padding: 40px;
              line-height: 1.6;
            }
            .container {
              max-width: 800px;
              margin: 0 auto;
              padding: 40px;
              box-shadow: 0 0 15px rgba(0,0,0,0.1);
            }
            h1 {
              font-family: 'Playfair Display', serif;
              font-size: 28px;
              color: #8b4513;
              text-align: center;
              margin-bottom: 30px;
              position: relative;
            }
            h1::after {
              content: '';
              display: block;
              width: 100px;
              height: 2px;
              background-color: #8b4513;
              position: absolute;
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
            }
            p {
              font-size: 18px;
              text-align: justify;
              text-indent: 30px;
              margin-bottom: 20px;
            }
            @media print {
              body { print-color-adjust: exact; -webkit-print-color-adjust: exact; }
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>${image.title}</h1>
            <p>${printableStory}</p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <Card
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: "100%",
          overflow: "hidden",
          boxShadow: 2,
          backgroundColor: "grey.100",
          cursor: status === "ready" ? "pointer" : "default",
        }}
        onClick={handleCardClick}
      >
        <CardContent
          sx={{
            p: 0,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            {image.downloadURL ? (
              <img
                src={image.downloadURL}
                alt="Gallery"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Typography variant="body2" color="text.secondary">
                Image not available
              </Typography>
            )}
          </Box>
          {status === "processing" && (
            <Tooltip title="Image is in progress for story generation" arrow>
              <RotatingBox
                sx={{
                  position: "absolute",
                  top: 8,
                  left: 8,
                  bgcolor: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "50%",
                  width: 36,
                  height: 36,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AutorenewIcon color="primary" />
              </RotatingBox>
            </Tooltip>
          )}
          {isExample && (
            <Chip
              label="Example"
              color="primary"
              size="small"
              sx={{
                position: "absolute",
                top: 8,
                left: 8,
              }}
            />
          )}
          {image.title && (
            <Box
              sx={{
                width: "100%",
                bgcolor: "rgba(255, 255, 255, 0.7)",
                p: 2,
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            >
              <Typography
                variant="subtitle1"
                noWrap
                fontWeight={400}
                sx={{ color: "black", mb: 3 }}
              >
                {image.title}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={isExpanded}
        onClose={handleClose}
        fullScreen={isMobile}
        maxWidth="md"
        fullWidth={!isMobile}
      >
        {isMobile && (
          <AppBar sx={{ position: "fixed", top: 0, left: 0, right: 0 }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {image.title}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent
          sx={{
            p: 0,
            mb: isMobile ? 10 : 0,
            position: "relative",
            height: isMobile ? "100vh" : "35vh",
            overflow: "auto",
            ...(isMobile && {
              pt: "56px", // Height of the AppBar
              pb: "64px", // Height of the fixed bottom bar
            }),
          }}
        >
          {!isMobile && (
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
                backgroundColor: "rgba(0,0,0,0.5)",
                "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              height: "100%",
            }}
          >
            <Box
              sx={{
                flex: { xs: "0 0 50%", md: 1 },
                position: "relative",
                minHeight: { xs: "50vh", md: "100%" },
                overflow: "hidden",
              }}
            >
              <img
                src={image.downloadURL}
                alt={image.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: isMobile ? "auto" : "100%",
                maxHeight: isMobile ? "none" : "100%",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  p: 3,
                  backgroundColor: "white",
                  overflow: "auto",
                }}
              >
                {!isMobile && (
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontFamily: '"Playfair Display", serif' }}
                  >
                    {image.title}
                  </Typography>
                )}
                <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                  {image.story}
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <Box
          sx={{
            p: 2,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(10px)",
            borderTop: "1px solid rgba(0, 0, 0, 0.2)",
            display: "flex",
            justifyContent: "flex-end",
            zIndex: 1,
            ...(isMobile && {
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }),
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyContent}
            sx={{ mr: 2 }}
          >
            Copy
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<PrintIcon />}
            onClick={handlePrintContent}
          >
            Print
          </Button>
        </Box>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Content copied to clipboard"
        sx={{ bottom: isMobile ? 90 : 0 }}
      />
    </>
  );
});

export default ImageCard;
