import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { auth, firestore } from "../config/firebase";
import { ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Container,
  Box,
  Paper,
  CircularProgress,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { Link } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import theme from "../config/theme";
import { copyrightText } from "../config/config";
import { trackPageView } from "../config/analytics";
import { useQuery } from "@tanstack/react-query";

const ReceiptsPage = () => {
  const [user] = useAuthState(auth);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: receipts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["receipts", user?.uid],
    queryFn: async () => {
      if (!user) return [];
      const receiptsRef = collection(firestore, `users/${user.uid}/payments`);
      const q = query(receiptsRef, orderBy("created", "desc"));
      try {
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        if (
          error.code === "failed-precondition" &&
          error.message.includes("missing index")
        ) {
          return [];
        }
        throw error;
      }
    },
    enabled: !!user,
  });

  useEffect(() => {
    trackPageView("Receipts Page");
    if (receipts && receipts.length > 0 && !selectedReceipt) {
      setSelectedReceipt(receipts[0]);
    }
  }, [receipts, selectedReceipt]);

  const handleReceiptChange = (event) => {
    const selectedId = event.target.value;
    const selected = receipts.find((receipt) => receipt.id === selectedId);
    setSelectedReceipt(selected);
  };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount / 100);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>QUILTTALE.COM Receipt</title>
          <style>
            body { font-family: Arial, sans-serif; background-color: #f5f5f5; margin: 0; padding: 20px; }
            .receipt { max-width: 800px; margin: 0 auto; background-color: white; padding: 40px; box-shadow: 0 2px 4px rgba(0,0,0,0.1); }
            .header { display: flex; align-items: center; margin-bottom: 20px; }
            .logo { width: 50px; height: 50px; margin-right: 15px; }
            .title { font-size: 24px; font-weight: bold; color: #1a1a6e; }
            .details { margin-bottom: 20px; }
            .details-row { display: flex; justify-content: space-between; margin-bottom: 10px; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: none; padding: 8px; text-align: left; }
            th { background-color: #f0f0f0; font-weight: bold; }
            .total-row { font-weight: bold; }
            .footer { margin-top: 30px; font-size: 0.8em; color: #666; text-align: center; }
          </style>
        </head>
        <body>
          <div class="receipt">
            <div class="header">
              <img src="/logo512.png" alt="QuiltTale Logo" class="logo">
              <div class="title">QuiltTale.com Receipt</div>
            </div>
            <div class="details">
              <div class="details-row">
                <span><strong>Payment Date:</strong> ${new Date(
                  selectedReceipt.created.seconds * 1000
                ).toLocaleDateString()}</span>
                <span><strong>Customer Email:</strong> ${
                  user ? user.email : "N/A"
                }</span>
              </div>
              <div class="details-row">
                <span><strong>Payment Confirmation #:</strong> ${selectedReceipt.id.slice(
                  -5
                )}</span>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Story Generation<br>
                    <small>Language: ${
                      selectedReceipt.metadata.language
                    }</small>
                  </td>
                  <td>${selectedReceipt.metadata.itemCount}</td>
                  <td>${formatCurrency(
                    parseFloat(selectedReceipt.metadata.pricePerItem) * 100,
                    selectedReceipt.currency
                  )}</td>
                  <td>${formatCurrency(
                    parseFloat(selectedReceipt.metadata.subtotal),
                    selectedReceipt.currency
                  )}</td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: right;">Subtotal</td>
                  <td>${formatCurrency(
                    parseFloat(selectedReceipt.metadata.subtotal),
                    selectedReceipt.currency
                  )}</td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: right;">Processing Fee</td>
                  <td>${formatCurrency(
                    parseFloat(selectedReceipt.metadata.stripeFee),
                    selectedReceipt.currency
                  )}</td>
                </tr>
                <tr class="total-row">
                  <td colspan="3" style="text-align: right;">Total</td>
                  <td>${formatCurrency(
                    selectedReceipt.amount,
                    selectedReceipt.currency
                  )}</td>
                </tr>
                <tr class="total-row">
                  <td colspan="3" style="text-align: right;">Amount Paid</td>
                  <td>${formatCurrency(
                    selectedReceipt.amount,
                    selectedReceipt.currency
                  )}</td>
                </tr>
              </tbody>
            </table>
            <div class="footer">
              Thank you for your business!
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const renderReceipt = (receipt) => {
    if (!receipt) return null;

    const {
      amount,
      currency,
      created,
      metadata: { itemCount, pricePerItem, language, subtotal, stripeFee },
    } = receipt;

    return (
      <Paper elevation={3} sx={{ p: 1, mt: 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Box
            sx={{
              mr: 2,
              width: "50px",
              height: "50px",
              flexShrink: 0,
            }}
          >
            <img
              src="/logo512.png"
              alt="QuiltTale Logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Typography variant="h5" align="center">
            QuiltTale.com Receipt
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ pl: 2, pr: 2 }}>
          <Typography variant="body2">
            <strong>Payment Date:</strong>{" "}
            {new Date(created.seconds * 1000).toLocaleDateString()}
          </Typography>
          <Typography variant="body2">
            <strong>Customer Email:</strong> {user ? user.email : "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Payment Confirmation #:</strong> {receipt.id.slice(-5)}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Story Generation
                  <Typography variant="body2" color="textSecondary">
                    Language: {language}
                  </Typography>
                </TableCell>
                <TableCell align="center">{itemCount}</TableCell>
                <TableCell align="right">
                  {formatCurrency(parseFloat(pricePerItem) * 100, currency)}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(parseFloat(subtotal), currency)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  Subtotal
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(parseFloat(subtotal), currency)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  Processing Fee
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(parseFloat(stripeFee), currency)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <strong>Total</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{formatCurrency(amount, currency)}</strong>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={3} align="right">
                  <strong>Amount Paid</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{formatCurrency(amount, currency)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">
          Error loading receipts: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <NavigationBar />
        <Box
          sx={{
            flexGrow: 1,
            py: 3,
            px: isMobile ? 0 : 2,
            background: theme.palette.background.default,
          }}
        >
          <Container maxWidth="md" disableGutters={isMobile}>
            <Typography variant="h1" align="center" gutterBottom>
              My Receipts
            </Typography>
            {receipts && receipts.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "600px",
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "stretch" : "center",
                      justifyContent: "space-between",
                      gap: 2,
                    }}
                  >
                    <FormControl
                      sx={{ flexGrow: 1, maxWidth: isMobile ? "100%" : "70%" }}
                    >
                      <InputLabel id="receipt-select-label">
                        Select Receipt
                      </InputLabel>
                      <Select
                        labelId="receipt-select-label"
                        value={selectedReceipt ? selectedReceipt.id : ""}
                        onChange={handleReceiptChange}
                        label="Select Receipt"
                      >
                        {receipts.map((receipt) => (
                          <MenuItem key={receipt.id} value={receipt.id}>
                            {formatDate(receipt.created)} -{" "}
                            {formatCurrency(receipt.amount, receipt.currency)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={handlePrint}
                      disabled={!selectedReceipt}
                      sx={{ minWidth: isMobile ? "100%" : "auto" }}
                    >
                      Print Receipt
                    </Button>
                  </Box>
                </Box>
                {selectedReceipt && (
                  <Box sx={{ width: "100%", maxWidth: "600px" }}>
                    {renderReceipt(selectedReceipt)}
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h5" gutterBottom>
                  You don't have any receipts yet.
                </Typography>
                <Typography variant="body1" paragraph>
                  Generate some stories to see your receipts here!
                </Typography>
                <Button
                  component={Link}
                  to="/upload"
                  variant="contained"
                  color="primary"
                >
                  Generate Stories
                </Button>
              </Box>
            )}
          </Container>
        </Box>
        <Box
          sx={{
            py: 3,
            background: theme.palette.background.default,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {copyrightText}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ReceiptsPage;
