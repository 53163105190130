import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  Menu,
  MenuItem,
  Chip,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CreateIcon from "@mui/icons-material/Create";
import CollectionsIcon from "@mui/icons-material/Collections";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../config/theme";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useQuery } from "@tanstack/react-query";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../config/firebase";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const LogoTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const NavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  color: theme.palette.text.primary,
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 2,
    left: "25%",
    width: "50%",
    height: 2,
    backgroundColor: isActive ? theme.palette.secondary.main : "transparent",
    transition: "background-color 0.3s ease",
  },
}));

const EmailChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}));

const truncateEmail = (email, maxLength = 20) => {
  if (email.length <= maxLength) return email;
  const [localPart, domain] = email.split("@");
  const truncatedLocalPart =
    localPart.slice(0, maxLength - domain.length - 4) + "...";
  return `${truncatedLocalPart}@${domain}`;
};

const NavigationBar = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: hasPayments } = useQuery({
    queryKey: ["hasPayments", user?.uid],
    queryFn: async () => {
      if (!user) return false;
      const paymentsRef = collection(firestore, `users/${user.uid}/payments`);
      const snapshot = await getDocs(paymentsRef);
      return !snapshot.empty;
    },
    enabled: !!user,
  });

  const truncatedEmail = user ? truncateEmail(user.email) : "";

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/");
    handleClose();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const navigationItems = [
    { label: "Generate Stories", path: "/upload", icon: <CreateIcon /> },
    { label: "My Gallery", path: "/gallery", icon: <CollectionsIcon /> },
    { label: "Feedback", path: "/feedback", icon: <FeedbackIcon /> },
  ];

  const renderNavigationItems = () => (
    <>
      {navigationItems.map((item) => (
        <NavButton
          key={item.path}
          isActive={location.pathname === item.path}
          onClick={() => {
            navigate(item.path);
            if (isMobile) setDrawerOpen(false);
          }}
          startIcon={item.icon}
        >
          {item.label}
        </NavButton>
      ))}
    </>
  );

  return (
    <StyledAppBar position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                mr: 2,
                width: "50px",
                height: "50px",
                flexShrink: 0,
              }}
            >
              <img
                src="/logo512.png"
                alt="QuiltTale Logo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <LogoTypography variant="h6" component="div">
              <span style={{ color: theme.palette.primary.main }}>QUILT</span>
              <span style={{ color: theme.palette.secondary.main }}>TALE</span>
            </LogoTypography>
          </Box>
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "2rem",
                }}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerToggle}
              >
                <Box sx={{ width: 250 }} role="presentation">
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Logged in as:
                    </Typography>
                    <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                      {user.email}
                    </Typography>
                  </Box>
                  <Divider />
                  <List>
                    {navigationItems.map((item) => (
                      <ListItemButton
                        key={item.path}
                        onClick={() => {
                          navigate(item.path);
                          setDrawerOpen(false);
                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    ))}
                    {hasPayments && (
                      <ListItemButton
                        onClick={() => {
                          navigate("/receipts");
                          setDrawerOpen(false);
                        }}
                      >
                        <ListItemIcon>
                          <ReceiptIcon />
                        </ListItemIcon>
                        <ListItemText primary="Receipts" />
                      </ListItemButton>
                    )}
                    <ListItemButton onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </List>
                </Box>
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {renderNavigationItems()}
              <EmailChip
                icon={<EmailIcon />}
                label={truncatedEmail}
                onClick={handleMenu}
                deleteIcon={<ArrowDropDownIcon />}
                onDelete={handleMenu}
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {hasPayments && (
                  <MenuItem
                    onClick={() => {
                      navigate("/receipts");
                      handleClose();
                    }}
                  >
                    <ReceiptIcon sx={{ mr: 1 }} /> Receipts
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  <LogoutIcon sx={{ mr: 1 }} /> Logout
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default NavigationBar;
