import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import theme from "../config/theme";

const ResponsiveBanner = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 3,
        gap: 2,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: "12px",
        padding: 3,
        position: "relative",
      }}
    >
      <Typography
        variant="overline"
        sx={{
          position: "absolute",
          top: -10,
          left: 16,
          backgroundColor: theme.palette.background.paper,
          padding: "0 8px",
          color: theme.palette.primary.main,
          fontWeight: "bold",
        }}
      >
        Example Story
      </Typography>
      <Box
        sx={{
          width: isMobile ? "100%" : "45%",
          height: isMobile ? "200px" : "350px",
          borderRadius: "8px",
          overflow: "hidden",
          boxShadow: theme.shadows[4],
        }}
      >
        <img
          src="assets/demo.jpg"
          alt="Garden of Memories"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box
        sx={{
          width: isMobile ? "100%" : "52%",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{
            color: theme.palette.primary.main,
            fontSize: { xs: "1.3rem", sm: "1.4rem", md: "1.5rem" },
            mb: 1,
          }}
        >
          Whispers of Roses
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            fontSize: { xs: "0.8rem", sm: "0.85rem", md: "0.9rem" },
            lineHeight: 1.4,
          }}
        >
          Each rose on this quilt whispers tales of love and resilience. The
          delicate petals, rendered in soft pastels, speak of gardens past,
          where stories were exchanged beneath the shade of blooming arbors. The
          intricate patterns weave a narrative of tenderness and the passage of
          time, evoking a sense of nostalgia for moments cherished and gone.
          <br />
          <br />
          As the petals unfold across the fabric, they invite contemplation on
          the beauty found in fleeting moments. This quilt is a poetic testament
          to nature's artistry, a gentle reminder that even in the quietest
          corners, life flourishes with grace and elegance. It's a piece that
          encourages one to pause and appreciate the subtle beauty that
          surrounds us every day.
        </Typography>
      </Box>
    </Box>
  );
};

export default ResponsiveBanner;
