import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

export const trackPageView = (pageName) => {
  logEvent(analytics, "page_view", {
    page_title: pageName,
    page_location: window.location.href,
    page_path: window.location.pathname,
  });
};

export const trackEvent = (eventName, eventParams = {}) => {
  logEvent(analytics, eventName, eventParams);
};
