// copyright
export const copyrightText =
  "© 2024 Quilt Tale. Crafting stories, one patch at a time.";

// settings for image uploads
export const maxImageDimension = 512;
export const maxImageUploads = 10;

// default price per image
export const pricePerImage = 1; // in USD
